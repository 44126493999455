import { PropsWithChildren } from "react"
import { BrowserRouter } from "react-router-dom"
import { AuthenticatedRoutes } from "./AuthenticatedRoutes/AuthenticatedRoutes"

export const Routes: React.FC<PropsWithChildren> = () => {
  return (
    <BrowserRouter>
      <AuthenticatedRoutes />
    </BrowserRouter>
  )
}
