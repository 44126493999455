import gsap from "gsap"
import { ScrollToPlugin } from "gsap/all"

gsap.registerPlugin(ScrollToPlugin)

export const scrollTo = (element: string, offset?: number) => {
  gsap.to(window, {
    scrollTo: { y: element, offsetY: offset || 0 },
  })
}
