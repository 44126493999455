import { useEffect, useRef } from "react"
import { useConsts } from "../../../../consts"
import { VanillaTilt } from "../../../../helpers/tilt"
import "./style.css"

type PlanBenefit = { included: boolean; description: string }

interface Props {
  img: string
  priceValue: string
  priceDescription: string
  priceTitle: string
  planBenefits: PlanBenefit[]
  backgroundColorFrom: string
  backgroundColorTo: string
  planDefaultMessage: string
  installmentPayment?: string
}

export const PaymentCard: React.FC<Props> = ({
  img,
  priceValue,
  priceTitle,
  priceDescription,
  planBenefits,
  backgroundColorFrom,
  backgroundColorTo,
  planDefaultMessage,
  installmentPayment,
}) => {
  const { consts } = useConsts()

  const paymentCardRef = useRef(null)

  useEffect(() => {
    if (paymentCardRef.current) {
      VanillaTilt.init(paymentCardRef.current, {
        max: 15,
        speed: 400,
        glare: false,
        "max-glare": 0.5,
      })
    }
  }, [])

  return (
    <div
      ref={paymentCardRef}
      className="payment-card-container"
      style={{
        backgroundImage: `linear-gradient(to bottom, ${backgroundColorFrom}, ${backgroundColorTo})`,
      }}
    >
      <div className="img-container">
        <img src={img} alt="" />
      </div>
      <div className="price-container">
        <div className="price-title">{priceTitle}</div>
        <div className="price-description">{priceDescription}</div>
        <div className="price-value-container">
          <div className="price-currency">R$</div>
          <div className="price-value">
            {installmentPayment && (
              <span style={{ fontSize: "24px" }}>{installmentPayment}</span>
            )}
            {priceValue},<span style={{ fontSize: "18px" }}>90</span>
          </div>
        </div>
        <div className="plan-benefits-container">
          {planBenefits.map((benefit, id) => (
            <div className="plan-benefit" key={id}>
              <img
                src={benefit.included ? "/check.svg" : "/error.svg"}
                alt=""
              />
              <p>{benefit.description}</p>
            </div>
          ))}
        </div>
        <div className="join-btn">
          <a
            style={{
              color: "#f2f2f2",
              textDecoration: "none",
              padding: "24px",
            }}
            href={`https://api.whatsapp.com/send?phone=5535998352267&text=${encodeURIComponent(
              planDefaultMessage
            )}`}
            target="_blank"
            rel="noreferrer"
          >
            {consts.NAVIGATION.START_NOW}
          </a>
        </div>
      </div>
    </div>
  )
}
