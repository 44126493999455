import { useEffect } from "react"
import { EventCard } from "../../Components/EventCard/EventCard"
import { Footer } from "../../Components/Footer/Footer"
import { Navbar } from "../../Components/Navbar/Navbar"
import { events } from "../../helpers/events"

export const Events = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" })
  }, [])

  const currentDate = new Date()

  const upcomingEvents = events.filter((event) => event.date > currentDate)
  const pastEvents = events.filter((event) => event.date < currentDate)

  return (
    <>
      <Navbar />
      <section style={{ padding: "24px", marginTop: "80px" }}>
        <div className="event-section-paper">
          <h1>Em breve...</h1>
          <div className="event-section-images-container">
            {upcomingEvents.map((event) => (
              <EventCard
                id={event.id}
                banner={event.banner}
                date={event.date}
                description={event.description}
                title={event.title}
                key={event.id}
              />
            ))}
          </div>
        </div>
        <div className="event-section-paper" style={{ marginTop: "80px" }}>
          <h1>Relembre</h1>
          <div className="event-section-images-container">
            {pastEvents.map((event) => (
              <EventCard
                id={event.id}
                banner={event.banner}
                date={event.date}
                description={event.description}
                title={event.title}
                key={event.id}
              />
            ))}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "24px",
          }}
        ></div>
      </section>

      <Footer />
    </>
  )
}
