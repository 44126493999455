import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useConsts } from "../../consts"
import { routesConsts } from "../../consts/routesContants"
import { scrollTo } from "../../helpers/scrollTo"
import "./style.css"

export const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false)
  const { consts, handleLanguageChange } = useConsts()
  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }

  const { pathname } = useLocation()

  const navigate = useNavigate()

  return (
    <nav className="menu-container">
      <div
        onClick={() => navigate("/")}
        className="desktop-logo-container"
        style={{ color: "#f2f2f2" }}
      >
        <img src={"/ironhouse-logo.svg"} alt="Logo da academia IronHouseGym" />
      </div>
      <div className="desktop-menu">
        <ul className="menu-wrapper">
          <li
            className="menu-item"
            onClick={() => {
              if (pathname !== "/") {
                navigate("/")
                scrollTo(".why-iron-house-container", 60)
              }
              scrollTo(".why-iron-house-container", 60)
            }}
          >
            {consts.NAVIGATION.ABOUT}
          </li>
          <li
            className="menu-item"
            onClick={() => {
              if (pathname !== "/") {
                navigate("/")
                scrollTo(".discover-container", -40)
              }
              scrollTo(".discover-container", -40)
            }}
          >
            {consts.NAVIGATION.KNOW_MORE}
          </li>
          <li
            className="menu-item"
            onClick={() => {
              if (pathname !== "/") {
                navigate("/")
                scrollTo(".kids-section-container", 60)
              }
              scrollTo(".kids-section-container", 60)
            }}
          >
            {consts.NAVIGATION.KIDS_AREA}
          </li>
          <li
            className="menu-item"
            onClick={() => {
              if (pathname !== "/") {
                navigate("/")
                scrollTo(".our-plans-container", 80)
              }
              scrollTo(".our-plans-container", 80)
            }}
          >
            {consts.NAVIGATION.PLANS}
          </li>
          <li
            className="menu-item"
            onClick={() => {
              navigate(routesConsts.gallery, { preventScrollReset: true })
            }}
          >
            {consts.NAVIGATION.GALLERY.LABEL}
          </li>
          <li
            className="menu-item"
            onClick={() => {
              navigate(routesConsts.events, { preventScrollReset: true })
            }}
          >
            {consts.NAVIGATION.EVENTS}
          </li>
          <li
            className="menu-item"
            onClick={() => {
              if (pathname !== "/") {
                navigate("/")
                scrollTo(".footer-container")
              }
              scrollTo(".footer-container")
            }}
          >
            {consts.NAVIGATION.CONTACT}
          </li>
          <button
            className="start-now-btn"
            onClick={() => {
              if (pathname !== "/") {
                navigate("/")
                scrollTo(".our-plans-container", 80)
              }
              scrollTo(".our-plans-container", 80)
            }}
          >
            {consts.NAVIGATION.START_NOW}
          </button>
          <div className="nav-wrapper">
            <div className="flags-modal">
              <div>
                <i
                  className={`sl-flag flag-${consts.NAVIGATION.LANGUAGE_SUFIX}`}
                ></i>
              </div>
              <b>{consts.NAVIGATION.CURRENT_LANGUAGE}</b>
            </div>
            <div className="sl-nav">
              <ul className="dropdown">
                <li
                  onClick={() => {
                    handleLanguageChange("Português (BR)")
                  }}
                >
                  <i className="sl-flag flag-br"></i>
                  <span>pt-BR</span>
                </li>

                <li
                  onClick={() => {
                    handleLanguageChange("English (US)")
                  }}
                >
                  <i className="sl-flag flag-us"></i>
                  <span>en-US</span>
                </li>

                <li
                  onClick={() => {
                    handleLanguageChange("Español (ES)")
                  }}
                >
                  <i className="sl-flag flag-es"></i>
                  <span>es-ES</span>
                </li>
              </ul>
            </div>
          </div>
        </ul>
      </div>

      <div className="mobile-menu-icon" onClick={toggleMenu}>
        &#9776;
      </div>

      <ul className={`menu-wrapper mobile-menu ${showMenu ? "show" : ""}`}>
        <div className="close-button" onClick={toggleMenu}>
          <img src={"/close.svg"} alt="Icone de fechar o menu" />
        </div>
        <div className="mobile-logo-container" style={{ color: "#f2f2f2" }}>
          <img
            src={"/ironhouse-logo.svg"}
            alt="Logo da academia IronHouseGym"
          />
        </div>
        <li
          className="menu-item"
          onClick={() => {
            toggleMenu()
            if (pathname !== "/") {
              navigate("/")
              scrollTo(".why-iron-house-container", 60)
            }
            scrollTo(".why-iron-house-container", 60)
          }}
        >
          {consts.NAVIGATION.ABOUT}
        </li>
        <li
          className="menu-item"
          onClick={() => {
            toggleMenu()
            if (pathname !== "/") {
              navigate("/")
              scrollTo(".discover-container", -40)
            }
            scrollTo(".discover-container", -40)
          }}
        >
          {consts.NAVIGATION.KNOW_MORE}
        </li>
        <li
          className="menu-item"
          onClick={() => {
            toggleMenu()
            if (pathname !== "/") {
              navigate("/")
              scrollTo(".kids-section-container", 60)
            }
            scrollTo(".kids-section-container", 60)
          }}
        >
          {consts.NAVIGATION.KIDS_AREA}
        </li>
        <li
          className="menu-item"
          onClick={() => {
            toggleMenu()
            if (pathname !== "/") {
              navigate("/")
              scrollTo(".our-plans-container", 80)
            }
            scrollTo(".our-plans-container", 80)
          }}
        >
          {consts.NAVIGATION.PLANS}
        </li>
        <li
          className="menu-item"
          onClick={() => {
            navigate(routesConsts.gallery, { preventScrollReset: true })
          }}
        >
          {consts.NAVIGATION.GALLERY.LABEL}
        </li>
        <li
          className="menu-item"
          onClick={() => {
            navigate(routesConsts.events, { preventScrollReset: true })
          }}
        >
          {consts.NAVIGATION.EVENTS}
        </li>
        <li
          className="menu-item"
          onClick={() => {
            toggleMenu()
            if (pathname !== "/") {
              navigate("/")
              scrollTo(".footer-container")
            }
            scrollTo(".footer-container")
          }}
        >
          {consts.NAVIGATION.CONTACT}
        </li>
        <button
          className="start-now-btn"
          onClick={() => {
            toggleMenu()
            if (pathname !== "/") {
              navigate("/")
              scrollTo(".our-plans-container", 80)
            }
            scrollTo(".our-plans-container", 80)
          }}
        >
          {consts.NAVIGATION.START_NOW}
        </button>
        <div className="nav-wrapper">
          <div className="flags-modal">
            <div>
              <i
                className={`sl-flag flag-${consts.NAVIGATION.LANGUAGE_SUFIX}`}
              ></i>
            </div>
            <b>{consts.NAVIGATION.CURRENT_LANGUAGE}</b>
          </div>
          <div className="sl-nav">
            <ul className="dropdown">
              <li
                onClick={() => {
                  handleLanguageChange("Português (BR)")
                  toggleMenu()
                }}
              >
                <i className="sl-flag flag-br"></i>
                <span>pt-BR</span>
              </li>

              <li
                onClick={() => {
                  handleLanguageChange("English (US)")
                  toggleMenu()
                }}
              >
                <i className="sl-flag flag-us"></i>
                <span>en-US</span>
              </li>

              <li
                onClick={() => {
                  handleLanguageChange("Español (ES)")
                  toggleMenu()
                }}
              >
                <i className="sl-flag flag-es"></i>
                <span>es-ES</span>
              </li>
            </ul>
          </div>
        </div>
      </ul>
    </nav>
  )
}
