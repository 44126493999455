const date = new Date()

const hour = date.getHours()

type Time = "morning" | "afternoon" | "night" | ""

let time: Time = ""

if (hour < 12) {
  time = "morning"
} else if (hour < 18) {
  time = "afternoon"
} else {
  time = "night"
}

const greeting =
  time === "morning"
    ? "good morning"
    : time === "afternoon"
    ? "good afternoon"
    : time === "night"
    ? "good night"
    : ""

export const en_us = {
  NAVIGATION: {
    ABOUT: "About",
    KNOW_MORE: "Learn more",
    PLANS: "Plans",
    KIDS_AREA: "Kids Area",
    CONTACT: "Contact",
    EVENTS: "Events",
    START_NOW: "Start now",
    LANGUAGE: "Language",
    CURRENT_LANGUAGE: "en-us",
    LANGUAGE_SUFIX: "us",
    GALLERY: {
      CTA: "See full gallery",
      LABEL: "Gallery",
      VISIT_FROM_BODYBUILDER: "Visit from bodybuilder",
      VISIT_FROM_SINGER: "Visit from singers",
      VISIT_FROM_PROFESSOR: "Visit from professor",
      TROPHIES: "Trophies we've already won",
      FEMALE_LOCKER_ROOM: "Female locker room",
      CLOTHING_STORE: "Clothing store",
    },
  },
  HERO: {
    HEADER_ONE: "Strengthen your mind",
    HEADER_TWO: "Training your body",
    CTA: "Imagine how far you could go if you just choose to start now.",
  },
  WHY_CHOOSE_US: {
    HEADER: "Why choose us?",
    REASONS: {
      ONE: {
        TITLE: "High-Quality Equipment",
        DESCRIPTION:
          "We offer a wide range of equipment to ensure you have access to the best resources to achieve your goals.",
      },
      TWO: {
        TITLE: "Qualified Instructors",
        DESCRIPTION:
          "Our team of instructors is highly qualified and dedicated to providing personalized guidance to help you.",
      },
      THREE: {
        TITLE: "Inspiring Environment",
        DESCRIPTION:
          "With a clean, modern, and energetic environment, you will feel motivated to reach your goals every time you walk through the door.",
      },
    },
    IMG_HOVER: {
      INTERIOR: "Gym interior",
      ENTRANCE: "Gym entrance",
      KIDS_AREA: "Kids Area",
      PERSON_WORKING_OUT: "Person working out",
    },
  },
  KNOW_US: {
    TITLE: "Get to know Iron House Gym",
    FIRST_PARAGRAPH:
      "At Iron House, we provide a complete gym experience, where every detail is designed for your comfort and convenience. With spacious and well-lit areas, you will find the perfect environment to exercise with peace of mind and focus.",
    SECOND_PARAGRAPH:
      "Our instructors are highly qualified and always available to offer personalized guidance, ensuring you make the most of each workout session. Additionally, our variety of equipment allows you to diversify your exercises and effectively reach your goals.",
    THIRD_PARAGRAPH:
      "And the best part? We have a Kids Area specially designed for the little ones! While you focus on your workouts, your children can have fun in a safe environment. Thus, the whole family can enjoy the benefits of a healthy lifestyle worry-free. Come visit us and discover why Iron House is the perfect choice for you and your family!",
  },
  KIDS: {
    TITLE: "No one to leave your little ones with?",
    FIRST_PARAGRAPH:
      "At Iron House, we understand the importance of providing a safe and fun environment for children while you focus on your workouts. Our Kids Area is specially designed to provide entertainment for the little ones, allowing you to train with peace of mind knowing your children are safe.",
    SECOND_PARAGRAPH:
      "Incorporating children's cartoons, a TV with Netflix, video games, a ball pit, chalkboards, and educational toys, the Kids area at Iron House provides fun and entertainment for children of all ages. While you exercise and take care of your health, your children can socialize, learn, and have fun in a safe and welcoming environment.",
    THIRD_PARAGRAPH:
      "Come pay us a visit and see how Iron House makes it easier for you to maintain an active and healthy lifestyle, with your little one by your side and under your watchful eyes at all times.",
    DISCLAIMER: "The kids area does not have monitors.",
    IMG_HOVER: "Ball pit in the Kids Area",
  },
  UTILS: {
    ATTENTION: "Attention",
  },
  PLANS: {
    JOIN_OUR_TEAM: "Join our team",
    PRICE_TABLE: "Price Table",
    MONTHLY: {
      TITLE: "Monthly",
      DESCRIPTION: "Ideal for those seeking flexibility.",
      MESSAGE: `Hello, ${greeting}. I would like to sign up for the monthly plan. How can I proceed?`,
    },
    QUARTERLY: {
      TITLE: "Quarterly",
      DESCRIPTION: "Balance between savings and benefits.",
      MESSAGE: `Hello, ${greeting}. I would like to sign up for the quarterly plan. How can I proceed?`,
    },
    SEMIANNUAL: {
      TITLE: "Semiannual",
      DESCRIPTION: "More advantages, fewer worries.",
      MESSAGE: `Hello, ${greeting}. I would like to sign up for the semiannual plan. How can I proceed?`,
    },
    ANNUAL: {
      TITLE: "Annual",
      DESCRIPTION: "Total commitment, giant benefits.",
      MESSAGE: `Hello, ${greeting}. I would like to sign up for the annual plan. How can I proceed?`,
    },
    BENEFITS: {
      FIRST: "7 days a week",
      SECOND: "Get 1 shaker",
      THIRD: "Get 1 free evaluation",
      FOURTH: "Get 1 #TEAMIRONHOUSEGYM shirt",
    },
  },
  FOOTER: {
    IRONHOUSE_DESCRIPTION:
      "Founded in 2023, Iron House Gym is more than just a gym; it's a space dedicated to physical and mental strength. Since our opening, we have been committed to providing an inspiring environment, state-of-the-art equipment, and a welcoming community to help our members achieve their goals.",
    INDEX: {
      TITLE: "Index",
      WHY_US: "Why Iron House Gym",
      KNOW_US: "Get to know Iron House Gym",
      KIDS_SPACE: "Kids Area",
      PRICING: "Plans",
    },
    OPENING_HOURS: {
      TITLE: "Opening Hours",
      FROM_MONDAY_TO_FRIDAY: {
        DAYS: "Monday - Friday",
        HOURS: "06:00 AM - 10:00 PM",
      },
      SATURDAY: {
        DAYS: "Saturday",
        HOURS: "09:00 AM - 03:00 PM",
      },
      SUNDAY: {
        DAYS: "Sundays and Holidays",
        HOURS: "09:00 AM - 12:00 PM",
      },
      HOLIDAYS_ON_WEEK_DAYS: {
        DAYS: "Holidays on weekdays",
        HOURS: "08:00 AM - 01:00 PM",
      },
    },
    ADDRESS: {
      LABEL: "Address",
      PHONE_LABEL: "Phone",
      STREET: "Rua Ribeiro da Luz, 181, São Lourenço",
      PHONE: "+55 (35) 99835-2267",
    },
  },
}
