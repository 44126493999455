import Providers from "./providers/Providers"
import { Routes } from "./routes/routes"

function App() {
  return (
    <Providers>
      <Routes />
    </Providers>
  )
}

export default App
