import { en_us } from "./en_us"
import { es_es } from "./es_es"
import { pt_br } from "./pt_br"

const languages = {
  "Português (BR)": pt_br,
  "English (US)": en_us,
  "Español (ES)": es_es,
} as const

export type langName = keyof typeof languages
export type langKeys = keyof typeof languages
export type langObjs = (typeof languages)[langName]

export default languages
