type Body = {
  title: string
  body: string
}

interface IEvent {
  id: string
  title: string
  description: string
  body?: Body[]
  banner: string
  images: string[]
  date: Date
}

export const events: IEvent[] = [
  {
    id: "aula-aberta-e-treino-de-poses-com-debora-paula",
    title:
      "Treino Aberto e Aula de Poses com a Campeã Wellness Débora Paula na IronHouse Gym!",
    description:
      "Não perca esta oportunidade única de se inspirar e aprender com uma das atletas mais renomadas! Junte-se a nós para um evento de poses com Débora Paula na nossa academia.",
    banner: "/wellness-athlete-visit.jpeg",
    body: [
      {
        title: "🏆 Quem é Débora Paula?",
        body: "Débora Paula é mais do que uma atleta; ela é uma verdadeira referência no mundo do fitness. Após conquistar o título de Campeã Overal em Roma, Itália, em 2022, Débora tornou-se uma profissional respeitada, competindo em diversos campeonatos ao redor do mundo, incluindo Espanha, Estados Unidos, Portugal, México e Canadá. Sua jornada de sucesso é um testemunho inspirador de dedicação, paixão e perseverança.",
      },
      {
        title: "🏋️‍♀️ O Que Esperar?",
        body: "Este evento extraordinário oferecerá uma oportunidade única para as mulheres da IronHouse Gym se conectarem com Débora Paula e aprenderem com sua expertise no mundo do fitness. Durante o treino aberto, Débora compartilhará seus métodos de treinamento e dicas valiosas para alcançar o máximo desempenho físico e mental.",
      },
      {
        title: "📅 Quando?",
        body: "Marque em sua agenda: o evento está marcado para 25/05/2024, treino às 10h e aula de poses às 15h na IronHouse Gym! Não perca esta oportunidade única de aprender com uma campeã e elevar sua jornada de fitness para novos patamares.",
      },
      {
        title: "🎟️ Como Participar?",
        body: "A participação neste evento exclusivo é aberta a todos os alunos da IronHouse Gym. Além disso, alunos de outras academias também podem participar, bastando pagar a diária de acesso no valor de R$ 25,00. Não é necessário fazer reserva, basta comparecer no dia do evento e participar desta experiência inspiradora.",
      },
    ],
    images: [],
    date: new Date("05/25/2024"),
  },
  {
    id: "presenca-vip-do-atleta-carlos-andre",
    title: "Presença VIP do atleta profissional Carlos André (Carlão)",
    description:
      "É com imensa alegria que a IronHouse Gym anuncia uma visita verdadeiramente especial do atleta profissional, Carlos André (Carlão), em uma presença VIP única!",
    banner: "/ironhouse-vip-presence-01.jpeg",
    body: [
      {
        title: "🏆 Quem é Carlos André?",
        body: "Carlos André não é apenas um atleta excepcional, mas um verdadeiro exemplo de determinação e excelência no mundo do esporte. Com uma dedicação incomparável ao treinamento e uma paixão contagiante pelo fitness, Carlos André inspira todos ao seu redor a alcançar seus objetivos e superar desafios.",
      },
      {
        title: "🏋️‍♂️ O Que Esperar?",
        body: "Prepare-se para uma experiência verdadeiramente transformadora! Durante a visita de Carlos André à nossa academia, os membros terão a oportunidade única de se encontrar com o próprio atleta, trocar experiências e receber insights valiosos sobre treinamento, mentalidade vencedora e como alcançar o máximo desempenho em cada sessão.\nAlém disso, Carlos André estará disponível para sessões de autógrafos e fotos exclusivas, criando memórias inesquecíveis para todos os presentes. Esta é uma chance imperdível de se conectar com um profissional dedicado e inspirador!",
      },
      {
        title: "📅 Quando?",
        body: "A presença VIP de Carlos André é reservada para os alunos da IronHouse Gym.\nNão deixe de participar deste evento épico e elevar sua jornada de fitness para novos patamares com a visita VIP de Carlos André à IronHouse Gym. Estamos ansiosos para recebê-lo em um dia repleto de inspiração, motivação e, é claro, muita energia positiva!",
      },
    ],
    images: [],
    date: new Date("04/22/2023"),
  },
  {
    id: "workshop-com-atleta-lucas-coelho",
    title: "Workshop com o atleta Olympia Lucas Coelho.",
    description:
      "Estamos entusiasmados em anunciar a presença especial de Lucas Coelho, um atleta de destaque no mundo do fisiculturismo, aqui na IronHouse Gym!",
    banner: "/ironhouse-vip-presence-02.jpeg",
    body: [
      {
        title: "🏆 Quem é Lucas Coelho?",
        body: "Lucas Coelho é uma figura inspiradora no cenário do fisiculturismo. Sua jornada para se tornar um atleta de elite é ainda mais notável porque ele superou desafios significativos. Antes de abraçar o esporte, Lucas enfrentou batalhas contra o vício e foi um dependente químico. No entanto, ele transformou sua vida por meio do fisiculturismo, encontrando no esporte uma nova paixão e propósito.",
      },
      {
        title: "🏋️‍♂️ O Que Esperar?",
        body: "Durante a visita de Lucas Coelho à IronHouse Gym, os membros terão a oportunidade única de se conectar com um verdadeiro exemplo de superação. Lucas estará compartilhando não apenas sua expertise em treinamento e nutrição, mas também sua inspiradora história de resiliência e determinação. Ele representa a prova viva de que é possível superar obstáculos e alcançar grandes feitos por meio do esporte.\nAlém disso, haverá sessões de fotos e autógrafos, permitindo que os presentes guardem lembranças especiais desse momento memorável. Esta é uma oportunidade imperdível de aprender com um atleta dedicado e se inspirar em sua jornada no mundo do fisiculturismo.",
      },
      {
        title: "📅 Quando?",
        body: "Marque em sua agenda: a visita de Lucas Coelho está agendada para 26/03/2023 na IronHouse Gym! Prepare-se para uma experiência enriquecedora e motivadora que certamente deixará uma marca duradoura em sua jornada fitness.",
      },
      {
        title: "🎟️ Como Participar?",
        body: "A presença de Lucas Coelho na IronHouse Gym está aberta a todos os membros da academia, bem como a alunos de outras academias que desejam participar, basta apenas pagar a diária. Não é necessário fazer reserva; basta comparecer no dia do evento e desfrutar dessa oportunidade única de se conectar com um atleta inspirador.",
      },
    ],
    images: [],
    date: new Date("03/26/2023"),
  },
]
