import gsap from "gsap"
import { useEffect, useRef } from "react"
import { useConsts } from "../../consts"
import "./style.css"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

export const Kids = () => {
  const { consts } = useConsts()

  const kidsSectionContainerRef = useRef(null)

  const word = "Área Kids"

  useEffect(() => {
    const ctx = gsap.context(() => {
      const letters = gsap.utils.toArray(".kids-letter")

      const tl = gsap.timeline({
        defaults: { ease: "power2.inOut" },
        scrollTrigger: {
          start: `-100px center`,
          trigger: kidsSectionContainerRef.current,
        },
      })

      tl.from(letters, {
        opacity: 0,
        y: 130,
        stagger: 0.05,
        ease: "back.out",
      })
        .from(".kids-section-text", {
          opacity: 0,
          delay: 0.2,
          y: 60,
          skewY: 3,
          stagger: { amount: 0.3 },
        })
        .from(".kids-images-container", { opacity: 0, skewY: 3 })
        .from(".kids-section-disclaimer-text", {
          opacity: 0,
          y: 60,
          skewY: 3,
        })
    })

    return () => ctx.revert()
  }, [])

  return (
    <>
      <div className="kids-section-container" ref={kidsSectionContainerRef}>
        <div className="letters-container">
          {word.split("").map((letter, index) => (
            <h1
              key={index}
              style={{ display: "inline-block" }}
              className={`kids-letter`}
            >
              {letter !== " " ? letter : <span>&nbsp;</span>}
            </h1>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "space-around",
            padding: "16px",
          }}
        >
          <div
            style={{
              maxHeight: "100vh",
              overflow: "hidden",
              padding: "24px",
              maxWidth: "850px",
            }}
          >
            <p className="kids-section-text">{consts.KIDS.FIRST_PARAGRAPH}</p>
            <p className="kids-section-text">{consts.KIDS.SECOND_PARAGRAPH}</p>
            <p className="kids-section-text">{consts.KIDS.THIRD_PARAGRAPH}</p>
            <div className="kids-section-disclaimer-text">
              <p>
                <b>{consts.UTILS.ATTENTION}: </b>
                {consts.KIDS.DISCLAIMER}
              </p>
            </div>
          </div>
          <div>
            <div
              className="kids-images-container"
              style={{ maxWidth: "400px", maxHeight: "300px" }}
            >
              <img
                src={"./ironhouse-area-kids.jpeg"}
                alt={consts.KIDS.IMG_HOVER}
                style={{
                  width: "100%",
                  maxHeight: "350px",
                  borderRadius: "8px",
                  objectFit: "cover",
                }}
                title={consts.KIDS.IMG_HOVER}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
