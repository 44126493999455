import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { langName } from "../consts/language"

type PersistedState = [langName, Dispatch<SetStateAction<langName>>]

const usePersistedState = (
  key: string,
  initialValue: langName
): PersistedState => {
  const getInitialValue = () => {
    const storedValue = localStorage.getItem(key) as langName
    return storedValue !== null ? storedValue : initialValue
  }

  const [state, setState] = useState(getInitialValue)

  useEffect(() => {
    localStorage.setItem(key, state)
  }, [key, state])

  return [state, setState]
}

export default usePersistedState
