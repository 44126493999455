import gsap from "gsap"
import { ScrollTrigger } from "gsap/all"
import { useEffect, useRef } from "react"
import { useConsts } from "../../consts"
import { Parallax } from "./Parallax/Parallax"

import { useIsMobile } from "../../helpers/useIsMobile"

import { useNavigate } from "react-router-dom"
import "./style.css"
import { routesConsts } from "../../consts/routesContants"

gsap.registerPlugin(ScrollTrigger)

export const Discover = () => {
  const container = useRef<HTMLDivElement>(null)
  const { consts } = useConsts()

  const { isMobile, isTablet } = useIsMobile()

  const navigate = useNavigate()

  useEffect(() => {
    const ctx = gsap.context(() => {
      const tl = gsap.timeline({
        defaults: { ease: "sine.in" },
        scrollTrigger: {
          trigger: ".discover-container",
          start: "top 80%",
          end: "bottom center",
        },
      })

      tl.from(".discover-section-header", {
        opacity: 0,
        delay: 0.3,
        y: 60,
        skewY: 4,
      })
      tl.from(".discover-section-text", {
        opacity: 0,
        delay: 0.3,
        y: 60,
        skewY: 3,
        stagger: { amount: 0.5 },
      })
    })

    return () => ctx.revert()
  }, [])

  return (
    <div className="discover-container" ref={container}>
      <section className="discover-section-text-cotainer">
        <div
          style={{
            maxWidth: `${isMobile ? "" : "80%"}`,

            display: "flex",
            gap: "24px",
            padding: "12px",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h1
            className="discover-section-header"
            style={{ fontSize: "2.5em", textAlign: "center" }}
          >
            {consts.KNOW_US.TITLE}
          </h1>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <p className="discover-section-text">
              {consts.KNOW_US.FIRST_PARAGRAPH}
            </p>
            <p className="discover-section-text">
              {consts.KNOW_US.SECOND_PARAGRAPH}
            </p>
            <p className="discover-section-text">
              {consts.KNOW_US.THIRD_PARAGRAPH}
            </p>
          </div>
        </div>
        {!isTablet && !isMobile && <Parallax />}
      </section>
      <section style={{ padding: "24px" }}>
        <div className="discover-section-paper">
          <div className="discover-section-images-container">
            <img
              loading="lazy"
              src={"gym-member-01.jpg"}
              alt=""
              className="image image-1"
            />
            <img
              loading="lazy"
              src={"gym-member-02.jpg"}
              alt=""
              className="image image-2"
            />
            <img
              loading="lazy"
              src={"gym-member-03.jpg"}
              alt=""
              className="image image-3"
            />
            <img
              loading="lazy"
              src={"gym-member-04.jpg"}
              alt=""
              className="image image-4"
            />
            <img
              loading="lazy"
              src={"gym-member-05.jpg"}
              alt=""
              className="image image-5"
            />
            <img
              loading="lazy"
              src={"gym-member-06.jpg"}
              alt=""
              className="image image-6"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "24px",
          }}
        >
          <button
            className="start-now-btn"
            style={{ padding: "24px 32px" }}
            onClick={() =>
              navigate(routesConsts.gallery, { preventScrollReset: true })
            }
          >
            {consts.NAVIGATION.GALLERY.CTA}
          </button>
        </div>
      </section>
    </div>
  )
}
