const date = new Date()

const hour = date.getHours()

type Time = "morning" | "afternoon" | "night" | ""

let time: Time = ""

if (hour < 12) {
  time = "morning"
} else if (hour < 18) {
  time = "afternoon"
} else {
  time = "night"
}

const greeting =
  time === "morning"
    ? "bom dia"
    : time === "afternoon"
    ? "boa tarde"
    : time === "night"
    ? "boa noite"
    : ""

export const pt_br = {
  NAVIGATION: {
    ABOUT: "Sobre",
    KNOW_MORE: "Conheça mais",
    KIDS_AREA: "Espaço Kids",
    CONTACT: "Contato",
    EVENTS: "Eventos",
    PLANS: "Planos",
    START_NOW: "Começar agora",
    LANGUAGE: "Idioma",
    CURRENT_LANGUAGE: "pt-br",
    LANGUAGE_SUFIX: "br",
    GALLERY: {
      CTA: "Ver galeria completa.",
      LABEL: "Galeria",
      VISIT_FROM_BODYBUILDER: "Visita do atleta",
      VISIT_FROM_SINGER: "Visita do cantor",
      VISIT_FROM_PROFESSOR: "Visita do professor",
      TROPHIES: "Troféus que já conqusitamos",
      FEMALE_LOCKER_ROOM: "Vestiário feminino",
      CLOTHING_STORE: "Loja de roupas",
    },
  },
  HERO: {
    HEADER_ONE: "Fortaleça a mente",
    HEADER_TWO: "Treinando o corpo",
    CTA: "Pense no quão longe você poderia chegar se apenas escolher começar agora.",
  },
  WHY_CHOOSE_US: {
    HEADER: "Por que escolher a gente ?",
    REASONS: {
      ONE: {
        TITLE: "Equipamentos de Alta Qualidade",
        DESCRIPTION:
          "Oferecemos uma ampla gama de equipamentos que irão garantir que você tenha acesso aos melhores recursos para atingir seus objetivos.",
      },
      TWO: {
        TITLE: "Instrutores Qualificados",
        DESCRIPTION:
          "Nossa equipe de instrutores é altamente qualificada e dedicada a fornecer orientação personalizada para ajudá-lo.",
      },
      THREE: {
        TITLE: "Ambiente Inspirador",
        DESCRIPTION:
          "Com um ambiente limpo, moderno e energético, você se sentirá motivado a alcançar seus objetivos todas as vezes que entrar pela porta.",
      },
    },
    IMG_HOVER: {
      INTERIOR: "Interior da academia",
      ENTRANCE: "Entrada da academia",
      KIDS_AREA: "Área Kids",
      PERSON_WORKING_OUT: "Aluno treinando",
    },
  },
  KNOW_US: {
    TITLE: "Conheça a Iron House Gym",
    FIRST_PARAGRAPH:
      "Na Iron House, proporcionamos uma experiência completa de academia, onde cada detalhe é pensado para o seu conforto e conveniência. Com espaços amplos e bem iluminados, você encontrará o ambiente perfeito para se exercitar com tranquilidade e foco.",
    SECOND_PARAGRAPH:
      "Nossos instrutores são altamente qualificados e estão sempre disponíveis para oferecer orientações personalizadas, garantindo que você aproveite ao máximo cada sessão de treino. Além disso, nossa variedade de equipamentos permite que você diversifique seus exercícios e alcance seus objetivos de forma eficaz.",
    THIRD_PARAGRAPH:
      "E o melhor de tudo? Temos uma área Kids especialmente projetada para os pequenos! Enquanto você se concentra em seus exercícios, seus filhos podem se divertir em um ambiente seguro. Assim, toda a família pode desfrutar dos benefícios de uma vida saudável sem preocupações. Venha nos visitar e descubra por que a Iron House é a escolha perfeita para você e sua família!",
  },
  KIDS: {
    TITLE: "Sem ter com quem deixar seus pequenos?",
    FIRST_PARAGRAPH:
      "Na Iron House, entendemos que é importante oferecer um ambiente seguro e divertido para as crianças enquanto você se concentra nos seus treinos. Nossa área Kids foi especialmente projetada para proporcionar entretenimento para os pequenos, permitindo que você treine com tranquilidade sabendo que seus filhos estão seguros.",
    SECOND_PARAGRAPH:
      "Equipada com desenhos infantis, TV com Netflix, video game, piscina de bolinhas, lousas e brinquedos educativos, a área Kids da Iron House oferece diversão e entretenimento para crianças de todas as idades. Enquanto você se exercita e cuida da sua saúde, seus filhos podem socializar, aprender e se divertir em um ambiente seguro e acolhedor.",
    THIRD_PARAGRAPH:
      "Venha nos visitar e descubra como a Iron House torna mais fácil para você manter um estilo de vida ativo e saudável, podendo ter seu pequeno (a) ao seu lado e de baixo dos seus olhos o tempo todo.",
    DISCLAIMER: "A área kids não possui monitores.",
    IMG_HOVER: "Piscina de bolinhas na Área Kids",
  },
  UTILS: {
    ATTENTION: "Atenção",
  },
  PLANS: {
    JOIN_OUR_TEAM: "Faça parte do nosso time",
    PRICE_TABLE: "Tabela de Preços",
    MONTHLY: {
      TITLE: "Mensal",
      DESCRIPTION: "Ideal para quem busca flexibilidade.",
      MESSAGE: `Olá, ${greeting}. Gostaria de contratar o plano mensal. Como posso proceder?`,
    },
    QUARTERLY: {
      TITLE: "Trimestral",
      DESCRIPTION: "Equilíbrio entre economia e benefícios.",
      MESSAGE: `Olá, ${greeting}. Gostaria de contratar o plano trimestral. Como posso proceder?`,
    },
    SEMIANNUAL: {
      TITLE: "Semestral",
      DESCRIPTION: "Mais vantagens, menos preocupações.",
      MESSAGE: `Olá, ${greeting}. Gostaria de contratar o plano semestral. Como posso proceder?`,
    },
    ANNUAL: {
      TITLE: "Anual",
      DESCRIPTION: "Compromisso total, benefícios gigantes.",
      MESSAGE: `Olá, ${greeting}. Gostaria de contratar o plano anual. Como posso proceder?`,
    },
    BENEFITS: {
      FIRST: "7 dias na semana",
      SECOND: "Ganhe 1 coqueteleira",
      THIRD: "Ganhe 1 avaliação gratuita",
      FOURTH: "Ganhe 1 blusa do #TEAMIRONHOUSEGYM",
    },
  },
  FOOTER: {
    IRONHOUSE_DESCRIPTION:
      "Surgida em 2023, a Iron House Gym é mais do que uma simples academia; é um espaço dedicado ao fortalecimento físico e mental. Desde nossa inauguração, nos empenhamos em oferecer um ambiente inspirador, equipamentos de última geração e uma comunidade acolhedora para ajudar nossos membros a alcançarem seus objetivos.",
    INDEX: {
      TITLE: "Índice",
      WHY_US: "Por que a Iron House Gym",
      KNOW_US: "Conheça a Iron House Gym",
      KIDS_SPACE: "Espaço Kids",
      PRICING: "Planos",
    },
    OPENING_HOURS: {
      TITLE: "Horários de funcionamento",
      FROM_MONDAY_TO_FRIDAY: {
        DAYS: "Segunda - Sexta",
        HOURS: "06:00 - 22:00",
      },
      SATURDAY: {
        DAYS: "Sábado",
        HOURS: "09:00 - 15:00",
      },
      SUNDAY: {
        DAYS: "Domingos e Feriados",
        HOURS: "09:00 - 12:00",
      },
      HOLIDAYS_ON_WEEK_DAYS: {
        DAYS: "Feriados em dias de semana",
        HOURS: "08:00 - 13:00",
      },
    },
    ADDRESS: {
      LABEL: "Endereço",
      PHONE_LABEL: "Telefone",
      STREET: "Rua Ribeiro da Luz, 181, São Lourenço",
      PHONE: "+55 (35) 99835-2267",
    },
  },
}
