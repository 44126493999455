import { Navbar } from "../../Components/Navbar/Navbar"

import "./style.css"
import { useEffect } from "react"
import { useConsts } from "../../consts"
import { Fab } from "../../Components/Fab/Fab"
import { Footer } from "../../Components/Footer/Footer"

export const Gallery = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" })
  }, [])

  const { consts } = useConsts()

  return (
    <>
      <Navbar />
      <Fab
        icon={"/whatsapp_digital_glyph_green.svg"}
        position="right bottom"
        size="medium"
      />
      <section style={{ padding: "24px", marginTop: "120px" }}>
        <div className="gallery-section-paper">
          <div className="gallery-section-images-container">
            <div className="gallery-section-img-item-container">
              <img
                loading="lazy"
                src={"/ironhouse-athlete-visit-01.jpeg"}
                alt={`${consts.NAVIGATION.GALLERY.VISIT_FROM_BODYBUILDER} Coelho`}
                className="gallery-image gallery-image-2"
              />
              <div className="gallery-section-img-description">
                {consts.NAVIGATION.GALLERY.VISIT_FROM_BODYBUILDER}{" "}
                <a
                  target="_blank"
                  href="https://www.instagram.com/coelhooficial21"
                  rel="noreferrer"
                >
                  Coelho
                </a>
              </div>
            </div>

            <div className="gallery-section-img-item-container">
              <img
                loading="lazy"
                src={"/ironhouse-athlete-visit-02.jpeg"}
                alt={`${consts.NAVIGATION.GALLERY.VISIT_FROM_BODYBUILDER} Carlos Andre`}
                className="gallery-image gallery-image-2 img-align-top"
              />
              <div className="gallery-section-img-description">
                <p>
                  {consts.NAVIGATION.GALLERY.VISIT_FROM_BODYBUILDER}{" "}
                  <a
                    target="_blank"
                    href="https://www.instagram.com/carlosandre.atletapro/"
                    rel="noreferrer"
                  >
                    Carlos Andre
                  </a>
                </p>
              </div>
            </div>

            <div className="gallery-section-img-item-container">
              <img
                loading="lazy"
                src={"/ironhouse-singers-01.jpeg"}
                alt={`${consts.NAVIGATION.GALLERY.VISIT_FROM_SINGER} Henrique e Diego`}
                className="gallery-image gallery-image-2"
              />
              <div className="gallery-section-img-description">
                <p>
                  {consts.NAVIGATION.GALLERY.VISIT_FROM_SINGER}{" "}
                  <a
                    target="_blank"
                    href="https://www.instagram.com/henriqueediego/"
                    rel="noreferrer"
                  >
                    Henrique e Diego
                  </a>
                </p>
              </div>
            </div>

            <div className="gallery-section-img-item-container">
              <img
                loading="lazy"
                src={"/ironhouse-singers-02.jpg"}
                alt={`${consts.NAVIGATION.GALLERY.VISIT_FROM_SINGER} José Neto e Frederico`}
                className="gallery-image gallery-image-2"
              />
              <div className="gallery-section-img-description">
                <p>
                  {consts.NAVIGATION.GALLERY.VISIT_FROM_SINGER}{" "}
                  <a
                    target="_blank"
                    href="https://www.instagram.com/joaonetoefrederico/"
                    rel="noreferrer"
                  >
                    José Neto e Frederico
                  </a>
                </p>
              </div>
            </div>

            <div className="gallery-section-img-item-container">
              <img
                loading="lazy"
                src="/ironhouse-visit-01.jpeg"
                alt={`${consts.NAVIGATION.GALLERY.VISIT_FROM_PROFESSOR} Santiago Paes Phd`}
                className="gallery-image gallery-image-2"
              />
              <div className="gallery-section-img-description">
                <p>
                  {consts.NAVIGATION.GALLERY.VISIT_FROM_PROFESSOR}{" "}
                  <a
                    target="_blank"
                    href="https://www.instagram.com/santiagopaesphd/"
                    rel="noreferrer"
                  >
                    Dr Santiago Paes
                  </a>
                </p>
              </div>
            </div>

            <div className="gallery-section-img-item-container">
              <img
                loading="lazy"
                src={"/female-locker-room.jpeg"}
                alt={consts.NAVIGATION.GALLERY.FEMALE_LOCKER_ROOM}
                className="gallery-image gallery-image-2"
              />
              <div className="gallery-section-img-description">
                <p>{consts.NAVIGATION.GALLERY.FEMALE_LOCKER_ROOM}</p>
              </div>
            </div>

            <div className="gallery-section-img-item-container">
              <img
                loading="lazy"
                src={"/ironhouse-store.jpeg"}
                alt={consts.NAVIGATION.GALLERY.CLOTHING_STORE}
                className="gallery-image gallery-image-2"
              />
              <div className="gallery-section-img-description">
                <p>{consts.NAVIGATION.GALLERY.CLOTHING_STORE}</p>
              </div>
            </div>

            <div className="gallery-section-img-item-container">
              <img
                loading="lazy"
                src={"/trophies.jpeg"}
                alt={consts.NAVIGATION.GALLERY.TROPHIES}
                className="gallery-image gallery-image-2"
              />
              <div className="gallery-section-img-description">
                <p>{consts.NAVIGATION.GALLERY.TROPHIES}</p>
              </div>
            </div>

            <div
              className="gallery-section-img-item-container"
              style={{ justifyContent: "none" }}
            >
              <img
                loading="lazy"
                src={"/gym-member-01.jpg"}
                alt=""
                className="gallery-image gallery-image-2"
                style={{ height: "100", flexGrow: 1 }}
              />
            </div>

            <div className="gallery-section-img-item-container">
              <img
                loading="lazy"
                src={"/gym-member-02.jpg"}
                alt=""
                className="gallery-image gallery-image-3"
              />
            </div>

            <div className="gallery-section-img-item-container">
              <img
                loading="lazy"
                src={"/gym-member-03.jpg"}
                alt=""
                className="gallery-image gallery-image-4"
              />
            </div>

            <div className="gallery-section-img-item-container">
              <img
                loading="lazy"
                src={"/gym-member-04.jpg"}
                alt=""
                className="gallery-image gallery-image-5"
              />
            </div>

            <div className="gallery-section-img-item-container">
              <img
                loading="lazy"
                src={"/gym-member-05.jpg"}
                alt=""
                className="gallery-image gallery-image-6"
              />
            </div>

            <div className="gallery-section-img-item-container">
              <img
                loading="lazy"
                src={"/gym-member-06.jpg"}
                alt=""
                className="gallery-image gallery-image-1"
              />
            </div>

            <div className="gallery-section-img-item-container">
              <img
                loading="lazy"
                src={"/gym-member-07.jpg"}
                alt=""
                className="gallery-image gallery-image-1"
              />
            </div>

            <div className="gallery-section-img-item-container">
              <img
                loading="lazy"
                src={"/gym-member-08.jpg"}
                alt=""
                className="gallery-image gallery-image-2"
              />
            </div>

            <div className="gallery-section-img-item-container">
              <img
                loading="lazy"
                src={"/gym-member-09.jpg"}
                alt=""
                className="gallery-image gallery-image-3"
              />
            </div>

            <div className="gallery-section-img-item-container">
              <img
                loading="lazy"
                src={"/gym-member-10.jpg"}
                alt=""
                className="gallery-image gallery-image-4"
              />
            </div>

            <div className="gallery-section-img-item-container">
              <img
                loading="lazy"
                src={"/gym-member-11.jpg"}
                alt=""
                className="gallery-image gallery-image-5"
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "24px",
          }}
        ></div>
      </section>
      <Footer />
    </>
  )
}
