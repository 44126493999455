import gsap from "gsap"
import { Flip, ScrollTrigger } from "gsap/all"
import { useEffect, useState } from "react"
import "./style.css"

import { useConsts } from "../../consts"
import { useIsMobile } from "../../helpers/useIsMobile"
import { Discover } from "../Discover/Discover"
import { Fab } from "../Fab/Fab"
import { Footer } from "../Footer/Footer"
import { Kids } from "../Kids/Kids"
import { Navbar } from "../Navbar/Navbar"
import { OurPlans } from "../OurPlans/OurPlans"
import { Gallery } from "./Components/Gallery"
import { WhyIronHouse } from "./Components/WhyIronHouse"

gsap.registerPlugin(ScrollTrigger, Flip)

export const HeroSection = () => {
  const [bigImage, setBigImage] = useState<HTMLElement>()
  const { consts } = useConsts()
  const { isMobile } = useIsMobile()

  useEffect(() => {
    const images: HTMLElement[] = gsap.utils.toArray("figure")
    setBigImage(images[0])

    images.forEach((image) => {
      image.addEventListener("click", () => changeGrid(image))
    })

    const changeGrid = (image: HTMLElement) => {
      if (image === bigImage) return

      setBigImage((prevBigImage) => {
        const state = Flip.getState(images)

        prevBigImage!.dataset.grid = image.dataset.grid
        image.dataset.grid = "img-1"

        Flip.from(state, {
          absolute: true,
          ease: "power1.inOut",
        })

        return image
      })
    }

    const ctx = gsap.context(() => {
      gsap.fromTo(
        ".cta",
        {
          y: 40,
          opacity: 0,
          stagger: 0.3,
          delay: 0.2,
        },
        {
          y: 0,
          opacity: 1,
          stagger: 0.3,
        }
      )

      const tl2 = gsap.timeline()

      tl2.from(".choose-us", {
        opacity: 0,
        delay: 0.3,
        x: 300,
        z: 300,
        skewY: 3,
      })

      tl2.from(".text-box", {
        opacity: 0,
        delay: 0.3,
        y: 60,
        skewY: 3,
        stagger: { amount: 0.5 },
      })

      tl2.fromTo(
        ".about-us-image",
        { opacity: 0 },
        { opacity: 1, stagger: 0.15 },
        "+=0"
      )

      ScrollTrigger.create({
        animation: tl2,
        trigger: ".background-container",
        start: "bottom center",
        end: "bottom center",
        toggleActions: "play none none none",
      })
    })

    return () => {
      images.forEach((image) => {
        image.removeEventListener("click", () => changeGrid(image))
      })
      ctx.revert()
    }

    // eslint-disable-next-line
  }, [])

  return (
    <div className="hero-section-container">
      <Navbar />
      <div className="background-container">
        <img
          src={`${isMobile ? "./banner-mobile.jpeg" : "./banner-desktop.jpeg"}`}
          alt=""
          className="banner"
        />
        <div className="banner-overlay" />
        <div className="text-holder" style={{ marginTop: "48px" }}>
          <p className="cta first">{consts.HERO.HEADER_ONE}</p>
          <h1 className="cta second" style={{ maxWidth: "max-content" }}>
            {consts.HERO.HEADER_TWO}
          </h1>
          <p className="cta third ">{consts.HERO.CTA}</p>
        </div>
      </div>
      <Fab
        icon={"./whatsapp_digital_glyph_green.svg"}
        position="right bottom"
        size="medium"
      />
      <div className="content-holder">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: `${isMobile ? "16px" : "64px"}`,
            color: "#f2f3f2",
            padding: "24px",
            maxWidth: "100%",
            flexWrap: "wrap",
          }}
        >
          <WhyIronHouse />
          <Gallery />
        </div>
      </div>
      <Discover />
      <Kids />
      <OurPlans />
      <Footer />
    </div>
  )
}
