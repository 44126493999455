import gsap from "gsap"
import { ScrollTrigger } from "gsap/all"
import { useEffect } from "react"

gsap.registerPlugin(ScrollTrigger)

export const Parallax = () => {
  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.fromTo(
        "#text_block-20-1219-left",
        { x: "-60%" },
        {
          x: "60%",
          scrollTrigger: {
            trigger: "#section-18-1219",
            start: "-60% center",
            end: "bottom center",
            scrub: true,
          },
        }
      )

      gsap.fromTo(
        "#text_block-20-1219-right",
        { x: "80%" },
        {
          x: "-69%",
          scrollTrigger: {
            trigger: "#section-18-1219",
            start: "-60% center",
            end: "bottom center",
            scrub: true,
          },
        }
      )
    })

    return () => {
      ctx.kill()
    }
  }, [])

  return (
    <section
      id="section-18-1219"
      className="ct-section"
      style={{ marginTop: "40px" }}
    >
      <div className="ct-section-inner-wrap">
        <p
          style={{ fontSize: "36px" }}
          id="text_block-20-1219-left"
          className="ct-text-block"
        >
          #GOBEYONDYOUR<span style={{ color: "#ff820e" }}>LIMITS</span>
        </p>
        <p
          style={{ fontSize: "36px" }}
          id="text_block-20-1219-middle"
          className="ct-text-block"
        >
          #TEAMIRON<span style={{ color: "#ff820e" }}>HOUSE</span>GYM
        </p>
        <p
          style={{ fontSize: "36px" }}
          id="text_block-20-1219-right"
          className="ct-text-block"
        >
          #NOWORK<span style={{ color: "#ff820e" }}>NOREWARD</span>
        </p>
      </div>
    </section>
  )
}
