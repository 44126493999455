import { useConsts } from "../../../consts"

export const Gallery = () => {
  const { consts } = useConsts()

  return (
    <div className="gallery">
      <figure
        className="about-us-image"
        data-grid="img-1"
        title={consts.WHY_CHOOSE_US.IMG_HOVER.ENTRANCE}
      >
        <img loading="lazy" src={"/ironhouse-front.jpeg"} alt="" />
      </figure>
      <figure
        className="about-us-image"
        data-grid="img-2"
        title={consts.WHY_CHOOSE_US.IMG_HOVER.PERSON_WORKING_OUT}
      >
        <img
          loading="lazy"
          src={"/gym-member-01.jpg"}
          alt={consts.WHY_CHOOSE_US.IMG_HOVER.PERSON_WORKING_OUT}
        />
      </figure>
      <figure
        className="about-us-image"
        data-grid="img-3"
        title={consts.WHY_CHOOSE_US.IMG_HOVER.INTERIOR}
      >
        <img
          loading="lazy"
          src={"/ironhouse-tv.jpg"}
          alt={consts.WHY_CHOOSE_US.IMG_HOVER.INTERIOR}
        />
      </figure>
      <figure className="about-us-image" data-grid="img-4" title="Área Kids">
        <img
          loading="lazy"
          src={"/ironhouse-kids.jpg"}
          alt={consts.WHY_CHOOSE_US.IMG_HOVER.INTERIOR}
        />
      </figure>
      <figure
        className="about-us-image"
        data-grid="img-5"
        title={consts.WHY_CHOOSE_US.IMG_HOVER.INTERIOR}
      >
        <img
          loading="lazy"
          src={"/ironhouse-interior.jpg"}
          alt={consts.WHY_CHOOSE_US.IMG_HOVER.INTERIOR}
        />
      </figure>
      <figure
        className="about-us-image"
        data-grid="img-6"
        title={consts.WHY_CHOOSE_US.IMG_HOVER.PERSON_WORKING_OUT}
      >
        <img
          loading="lazy"
          src={"/gym-member-08.jpg"}
          alt={consts.WHY_CHOOSE_US.IMG_HOVER.PERSON_WORKING_OUT}
        />
      </figure>
    </div>
  )
}
