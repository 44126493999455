import { useConsts } from "../../consts"
import "./style.css"

import { scrollTo } from "../../helpers/scrollTo"

export const Footer = () => {
  const { consts } = useConsts()

  return (
    <footer className="footer-container">
      <div className="footer-text-container">
        <div className="footer-logo-container">
          <img src={"/ironhouse-logo.svg"} alt="Iron House Gym Logo" />
        </div>
        <div className="social-medias-container">
          <a
            href="https://www.instagram.com/ironhousegymsl/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={"/instagram.svg"}
              alt="Instagram icon in svg format"
              className="footer-instagram-icon"
            />
          </a>
          <a
            href="https://wa.me/5535998352267"
            target="_blank"
            rel="noreferrer"
          >
            <img
              style={{ width: "38px" }}
              src={"/whatsapp_digital_glyph_green.svg"}
              alt="Instagram icon in svg format"
              className="footer-instagram-icon"
            />
          </a>
        </div>
        <p style={{ textAlign: "justify" }}>
          {consts.FOOTER.IRONHOUSE_DESCRIPTION}
        </p>
      </div>
      <div className="opening-hours">
        <h2>{consts.FOOTER.OPENING_HOURS.TITLE}</h2>
        <ul>
          <li>
            <div>
              <div className="days text-highlight">
                {consts.FOOTER.OPENING_HOURS.FROM_MONDAY_TO_FRIDAY.DAYS}
              </div>
              <div className="hours">
                {consts.FOOTER.OPENING_HOURS.FROM_MONDAY_TO_FRIDAY.HOURS}
              </div>
            </div>
          </li>
          <li>
            <div>
              <div className="days text-highlight">
                {consts.FOOTER.OPENING_HOURS.SATURDAY.DAYS}
              </div>
              <div className="hours">
                {consts.FOOTER.OPENING_HOURS.SATURDAY.HOURS}
              </div>
            </div>
          </li>
          <li>
            <div>
              <div className="days text-highlight">
                {consts.FOOTER.OPENING_HOURS.SUNDAY.DAYS}
              </div>
              <div className="hours">
                {consts.FOOTER.OPENING_HOURS.SUNDAY.HOURS}
              </div>
            </div>
          </li>
          <li>
            <div>
              <div className="days text-highlight">
                {consts.FOOTER.OPENING_HOURS.HOLIDAYS_ON_WEEK_DAYS.DAYS}
              </div>
              <div className="hours">
                {consts.FOOTER.OPENING_HOURS.HOLIDAYS_ON_WEEK_DAYS.HOURS}
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div className="find-us">
        <div className="address">
          <h2>{consts.FOOTER.ADDRESS.LABEL}</h2>
          <p className="text-highlight">{consts.FOOTER.ADDRESS.STREET}</p>
        </div>
        <div className="telephone">
          <h2>{consts.FOOTER.ADDRESS.PHONE_LABEL}</h2>
          <p className="text-highlight">{consts.FOOTER.ADDRESS.PHONE}</p>
        </div>
      </div>
      <div className="index">
        <h2>{consts.FOOTER.INDEX.TITLE}</h2>
        <ul>
          <li
            className="text-highlight"
            onClick={() => {
              scrollTo(".why-iron-house-container", 60)
            }}
            style={{ cursor: "pointer" }}
          >
            {consts.FOOTER.INDEX.WHY_US}
          </li>
          <li
            className=""
            onClick={() => {
              scrollTo(".discover-container")
            }}
            style={{ cursor: "pointer" }}
          >
            {consts.FOOTER.INDEX.KNOW_US}
          </li>
          <li
            className="text-highlight"
            onClick={() => {
              scrollTo(".kids-section-container")
            }}
            style={{ cursor: "pointer" }}
          >
            {consts.FOOTER.INDEX.KIDS_SPACE}
          </li>
          <li
            className=""
            onClick={() => {
              scrollTo(".our-plans-container")
            }}
            style={{ cursor: "pointer" }}
          >
            {consts.FOOTER.INDEX.PRICING}
          </li>
        </ul>
      </div>
    </footer>
  )
}
