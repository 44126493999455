const date = new Date()

const hour = date.getHours()

type Time = "morning" | "afternoon" | "night" | ""

let time: Time = ""

if (hour < 12) {
  time = "morning"
} else if (hour < 18) {
  time = "afternoon"
} else {
  time = "night"
}

const greeting =
  time === "morning"
    ? "buen día"
    : time === "afternoon"
    ? "buenas tardes"
    : time === "night"
    ? "buenas noches"
    : ""

export const es_es = {
  NAVIGATION: {
    ABOUT: "Acerca de",
    KNOW_MORE: "Conoce más",
    KIDS_AREA: "Área infantil",
    CONTACT: "Contacto",
    EVENTS: "Eventos",
    PLANS: "Planos",
    START_NOW: "Comenzar ahora",
    LANGUAGE: "Idioma",
    CURRENT_LANGUAGE: "es-es",
    LANGUAGE_SUFIX: "es",
    GALLERY: {
      CTA: "Ver galería completa.",
      LABEL: "Galeria",
      VISIT_FROM_BODYBUILDER: "Visita del culturista",
      VISIT_FROM_SINGER: "Visita de los cantantes cantante",
      VISIT_FROM_PROFESSOR: "Visita del professor",
      TROPHIES: "Trofeos que ya hemos ganado",
      FEMALE_LOCKER_ROOM: "Vestuario femenino",
      CLOTHING_STORE: "Tienda de ropa",
    },
  },
  HERO: {
    HEADER_ONE: "Fortalece tu mente",
    HEADER_TWO: "Entrenando tu cuerpo",
    CTA: "Imagina hasta dónde podrías llegar si solo decides comenzar ahora.",
  },
  WHY_CHOOSE_US: {
    HEADER: "¿Por qué elegir Iron House Gym?",
    REASONS: {
      ONE: {
        TITLE: "Equipos de Alta Calidad",
        DESCRIPTION:
          "Ofrecemos una amplia gama de equipos para asegurarnos de que tengas acceso a los mejores recursos para alcanzar tus objetivos.",
      },
      TWO: {
        TITLE: "Instructores Calificados",
        DESCRIPTION:
          "Nuestro equipo de instructores está altamente calificado y dedicado a brindar orientación personalizada para ayudarte.",
      },
      THREE: {
        TITLE: "Ambiente Inspirador",
        DESCRIPTION:
          "Con un ambiente limpio, moderno y enérgico, te sentirás motivado para alcanzar tus objetivos cada vez que entres por la puerta.",
      },
    },
    IMG_HOVER: {
      INTERIOR: "Interior del gimnasio",
      ENTRANCE: "Entrada del gimnasio",
      KIDS_AREA: "Área Infantil",
      PERSON_WORKING_OUT: "Persona entrenando",
    },
  },
  KNOW_US: {
    TITLE: "Conoce Iron House Gym",
    FIRST_PARAGRAPH:
      "En Iron House, proporcionamos una experiencia completa de gimnasio, donde cada detalle está pensado para tu comodidad y conveniencia. Con áreas amplias y bien iluminadas, encontrarás el ambiente perfecto para hacer ejercicio con tranquilidad y enfoque.",
    SECOND_PARAGRAPH:
      "Nuestros instructores están altamente calificados y siempre disponibles para ofrecer orientación personalizada, asegurando que aproveches al máximo cada sesión de entrenamiento. Además, nuestra variedad de equipos te permite diversificar tus ejercicios y alcanzar tus objetivos de manera efectiva.",
    THIRD_PARAGRAPH:
      "¿Y lo mejor de todo? ¡Tenemos un Área Infantil especialmente diseñada para los más pequeños! Mientras te concentras en tus entrenamientos, tus hijos pueden divertirse en un ambiente seguro. Así, toda la familia puede disfrutar de los beneficios de un estilo de vida saludable sin preocupaciones. ¡Ven a visitarnos y descubre por qué Iron House es la elección perfecta para ti y tu familia!",
  },
  KIDS: {
    TITLE: "¿No tienes con quién dejar a tus pequeños?",
    FIRST_PARAGRAPH:
      "En Iron House, entendemos la importancia de ofrecer un ambiente seguro y divertido para los niños mientras te concentras en tus entrenamientos. Nuestro Área Infantil está especialmente diseñada para brindar entretenimiento a los más pequeños, permitiéndote entrenar con tranquilidad sabiendo que tus hijos están seguros.",
    SECOND_PARAGRAPH:
      "Equipada con dibujos animados infantiles, TV con Netflix, videojuegos, piscina de bolas, pizarras y juguetes educativos, el área para niños de Iron House ofrece diversión y entretenimiento para niños de todas las edades. Mientras tú haces ejercicio y cuidas de tu salud, tus hijos pueden socializar, aprender y divertirse en un ambiente seguro y acogedor.",
    THIRD_PARAGRAPH:
      "Ven a visitarnos y descubre cómo Iron House hace más fácil para ti mantener un estilo de vida activo y saludable, pudiendo tener a tu pequeño(a) a tu lado y bajo tu mirada todo el tiempo.",
    DISCLAIMER: "El área infantil no cuenta con monitores.",
    IMG_HOVER: "Piscina de pelotas en el Área Infantil",
  },
  UTILS: {
    ATTENTION: "Atención",
  },
  PLANS: {
    JOIN_OUR_TEAM: "Únete a nuestro equipo",
    PRICE_TABLE: "Tabla de Precios",
    MONTHLY: {
      TITLE: "Mensual",
      DESCRIPTION: "Ideal para quienes buscan flexibilidad.",
      MESSAGE: `Hola, ${greeting}. Me gustaría inscribirme en el plan mensual. ¿Cómo puedo proceder?`,
    },
    QUARTERLY: {
      TITLE: "Trimestral",
      DESCRIPTION: "Balance entre ahorro y beneficios.",
      MESSAGE: `Hola, ${greeting}. Me gustaría inscribirme en el plan trimestral. ¿Cómo puedo proceder?`,
    },
    SEMIANNUAL: {
      TITLE: "Semestral",
      DESCRIPTION: "Más ventajas, menos preocupaciones.",
      MESSAGE: `Hola, ${greeting}. Me gustaría inscribirme en el plan semestral. ¿Cómo puedo proceder?`,
    },
    ANNUAL: {
      TITLE: "Anual",
      DESCRIPTION: "Compromiso total, beneficios gigantes.",
      MESSAGE: `Hola, ${greeting}. Me gustaría inscribirme en el plan anual. ¿Cómo puedo proceder?`,
    },
    BENEFITS: {
      FIRST: "7 días a la semana",
      SECOND: "Recibe 1 coctelera",
      THIRD: "Recibe 1 evaluación gratuita",
      FOURTH: "Recibe 1 camiseta de #TEAMIRONHOUSEGYM",
    },
  },
  FOOTER: {
    IRONHOUSE_DESCRIPTION:
      "Fundada en 2023, Iron House Gym es más que un simple gimnasio; es un espacio dedicado al fortalecimiento físico y mental. Desde nuestra apertura, nos hemos comprometido a ofrecer un ambiente inspirador, equipos de última generación y una comunidad acogedora para ayudar a nuestros miembros a alcanzar sus objetivos.",
    INDEX: {
      TITLE: "Índice",
      WHY_US: "¿Por qué Iron House Gym?",
      KNOW_US: "Conoce Iron House Gym",
      KIDS_SPACE: "Área Infantil",
      PRICING: "Planes",
    },
    OPENING_HOURS: {
      TITLE: "Horario de Apertura",
      FROM_MONDAY_TO_FRIDAY: {
        DAYS: "Lunes - Viernes",
        HOURS: "06:00 - 22:00",
      },
      SATURDAY: {
        DAYS: "Sábado",
        HOURS: "09:00 - 15:00",
      },
      SUNDAY: {
        DAYS: "Domingos y festivos",
        HOURS: "09:00 - 12:00",
      },
      HOLIDAYS_ON_WEEK_DAYS: {
        DAYS: "Feriados en días laborables",
        HOURS: "08:00 - 13:00",
      },
    },
    ADDRESS: {
      LABEL: "Dirección",
      PHONE_LABEL: "Teléfono",
      STREET: "Rua Ribeiro da Luz, 181, São Lourenço",
      PHONE: "+55 (35) 99835-2267",
    },
  },
} as const
