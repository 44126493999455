import React from "react"

interface IFab extends Omit<React.HTMLProps<HTMLDivElement>, "size"> {
  icon: string
  position: "left bottom" | "left top" | "right bottom" | "right top"
  size?: "small" | "medium" | "large" | "custom"
  customSize?: { width: string; height: string }
}

export const Fab: React.FC<IFab> = ({
  icon,
  position,
  size = "medium",
  customSize,
  ...rest
}) => {
  let fabSize = {}
  switch (size) {
    case "small":
      fabSize = { width: "24px", height: "24px" }
      break
    case "medium":
      fabSize = { width: "36px", height: "36px" }
      break
    case "large":
      fabSize = { width: "48px", height: "48px" }
      break
    case "custom":
      fabSize = customSize || { width: "36px", height: "36px" }
      break
    default:
      fabSize = { width: "36px", height: "36px" }
  }

  const [vertical, horizontal] = position.split(" ")

  let fabPosition = {
    [vertical]: "36px",
    [horizontal]: "36px",
  }

  return (
    <div
      className="click-to-chat"
      style={{
        position: "fixed",
        transform: "translate(-50%, -50%)",
        zIndex: 10,
        ...fabPosition,
        ...fabSize,
      }}
      {...rest}
    >
      <a
        aria-label="Chat on WhatsApp"
        href="https://wa.me/5535998352267"
        target="_blank"
        rel="noreferrer"
      >
        <img
          alt="Chat on WhatsApp"
          style={{ width: "100%", height: "100%" }}
          src={icon}
        />
      </a>
    </div>
  )
}
