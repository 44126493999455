import { useNavigate } from "react-router-dom"
import { routesConsts } from "../../consts/routesContants"
import "./style.css"

interface IEventCard {
  id: string
  banner: string
  title: string
  date: Date
  description: string
}

export const EventCard: React.FC<IEventCard> = ({
  id,
  banner,
  title,
  date,
  description,
}) => {
  const navigate = useNavigate()

  return (
    <div className="event-card-container">
      <div className="event-card-img-container">
        <img src={banner} alt="" />
      </div>
      <div className="event-card-info-container">
        <div className="event-card-title-container">
          <p className="event-card-title">{title}</p>
        </div>
        <p className="event-card-date">
          {Intl.DateTimeFormat("pt-BR").format(date)}
        </p>
      </div>
      <div className="event-card-description" style={{ height: "76px" }}>
        <p>{description}</p>
      </div>
      <div
        className="event-card-cta start-now-btn"
        onClick={() => navigate(`${routesConsts.events}/${id}`)}
      >
        Saiba mais
      </div>
    </div>
  )
}
