import { Route, Routes } from "react-router-dom"
import { HeroSection } from "../../Components/HeroSection/HeroSection"
import { Gallery } from "../../pages/Gallery/Gallery"
import { Redirect } from "../../pages/Redirect/Redirect"
import { Events } from "../../pages/Events/Events"
import { routesConsts } from "../../consts/routesContants"
import { EventsFull } from "../../pages/EventsFull/EventsFull"

export const AuthenticatedRoutes: React.FC = () => {
  return (
    <Routes>
      <Route element={<HeroSection />} path={routesConsts.index} />
      <Route element={<Gallery />} path={routesConsts.gallery} />
      <Route element={<Events />} path={routesConsts.events} />
      <Route element={<EventsFull />} path={`${routesConsts.events}/:postId`} />
      <Route element={<Redirect />} path={routesConsts.notFound} />
    </Routes>
  )
}
