import { useState, useEffect } from "react"

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 450)
  const [isTablet, setIsTablet] = useState<boolean>(
    window.innerWidth >= 450 && window.innerWidth < 950
  )

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 450)
    setIsTablet(window.innerWidth >= 450 && window.innerWidth < 950)
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return { isTablet, isMobile }
}
