import { useEffect } from "react"
import { PaymentCard } from "./Components/PaymentCard/PaymentCard"
import gsap from "gsap"
import { useConsts } from "../../consts"

export const OurPlans = () => {
  const { consts } = useConsts()

  useEffect(() => {
    const ctx = gsap.context(() => {
      const paymentCards: HTMLDivElement[] = gsap.utils.toArray(
        ".payment-card-container"
      )

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".our-plans-container",
          start: "top bottom",
        },
      })

      tl.fromTo(
        ".price-table",
        { y: 40, opacity: 0 },
        { opacity: 1, y: 0, duration: 0.3 }
      )
      tl.fromTo(
        ".join-our-team",
        { y: 40, opacity: 0 },
        { opacity: 1, y: 0, duration: 0.3 }
      )

      paymentCards.forEach((paymentCard) => {
        gsap.fromTo(
          paymentCard,
          { y: 40, opacity: 0 },
          {
            opacity: 1,
            y: 0,
            duration: 1,
            ease: "back.inOut",
            scrollTrigger: {
              trigger: paymentCard,
            },
          }
        )
      })
    })

    return () => {
      ctx.kill()
    }

    // eslint-disable-next-line
  }, [])

  return (
    <div className="our-plans-container" style={{ margin: "120px 0px" }}>
      <div style={{ margin: "24px" }}>
        <p
          className="price-table"
          style={{
            textAlign: "center",
          }}
        >
          {consts.PLANS.PRICE_TABLE}
        </p>
        <h1
          className="join-our-team"
          style={{
            textTransform: "uppercase",
            textAlign: "center",
            margin: "8px 0px",
          }}
        >
          {consts.PLANS.JOIN_OUR_TEAM}
        </h1>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          flexWrap: "wrap",
          gap: "24px",
        }}
      >
        <PaymentCard
          backgroundColorFrom="#E6C434"
          backgroundColorTo="#DC8502"
          priceTitle={consts.PLANS.MONTHLY.TITLE}
          priceDescription={consts.PLANS.MONTHLY.DESCRIPTION}
          priceValue="129"
          planBenefits={[
            { included: true, description: consts.PLANS.BENEFITS.FIRST },
            { included: false, description: consts.PLANS.BENEFITS.SECOND },
            { included: false, description: consts.PLANS.BENEFITS.THIRD },
          ]}
          img="/bike.png"
          planDefaultMessage={consts.PLANS.MONTHLY.MESSAGE}
        />
        <PaymentCard
          backgroundColorFrom="#3DCAD4"
          backgroundColorTo="#14575c"
          priceTitle={consts.PLANS.QUARTERLY.TITLE}
          priceDescription={consts.PLANS.QUARTERLY.DESCRIPTION}
          priceValue="119"
          planBenefits={[
            { included: true, description: consts.PLANS.BENEFITS.FIRST },
            { included: false, description: consts.PLANS.BENEFITS.SECOND },
            { included: false, description: consts.PLANS.BENEFITS.THIRD },
          ]}
          img="/squat.png"
          planDefaultMessage={consts.PLANS.QUARTERLY.MESSAGE}
          installmentPayment={"3x"}
        />
        <PaymentCard
          backgroundColorFrom="#3197b6"
          backgroundColorTo="#0a3b4b"
          priceTitle={consts.PLANS.SEMIANNUAL.TITLE}
          priceDescription={consts.PLANS.SEMIANNUAL.DESCRIPTION}
          priceValue="109"
          planBenefits={[
            { included: true, description: consts.PLANS.BENEFITS.FIRST },
            { included: true, description: consts.PLANS.BENEFITS.SECOND },
            { included: false, description: consts.PLANS.BENEFITS.THIRD },
          ]}
          img="/sholder-press.png"
          planDefaultMessage={consts.PLANS.SEMIANNUAL.MESSAGE}
          installmentPayment={"6x"}
        />
        <PaymentCard
          img="/leg-press.png"
          backgroundColorFrom="#193664"
          backgroundColorTo="#5283b1"
          priceTitle={consts.PLANS.ANNUAL.TITLE}
          priceDescription={consts.PLANS.ANNUAL.DESCRIPTION}
          priceValue="99"
          planBenefits={[
            { included: true, description: consts.PLANS.BENEFITS.FIRST },
            { included: true, description: consts.PLANS.BENEFITS.SECOND },
            { included: true, description: consts.PLANS.BENEFITS.THIRD },
          ]}
          planDefaultMessage={consts.PLANS.ANNUAL.MESSAGE}
          installmentPayment={"12x"}
        />
      </div>
    </div>
  )
}
