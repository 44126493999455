import { TextBox } from "./TextBox"

import { useConsts } from "../../../consts"

export const WhyIronHouse = () => {
  const { consts } = useConsts()

  return (
    <div className="why-iron-house-container">
      <div style={{ maxWidth: "500px", width: "100%" }}>
        <p className="choose-us" style={{ fontSize: "2.5em" }}>
          {consts.WHY_CHOOSE_US.HEADER}
        </p>
      </div>
      <div className="text-box">
        <TextBox
          icon={"./dumbbell_icon.svg"}
          text={consts.WHY_CHOOSE_US.REASONS.ONE.TITLE}
          description={consts.WHY_CHOOSE_US.REASONS.ONE.DESCRIPTION}
        />
      </div>
      <div className="text-box">
        <TextBox
          icon={"./house_icon.svg"}
          text={consts.WHY_CHOOSE_US.REASONS.TWO.TITLE}
          description={consts.WHY_CHOOSE_US.REASONS.TWO.DESCRIPTION}
        />
      </div>
      <div className="text-box">
        <TextBox
          icon={"./people_icon.svg"}
          text={consts.WHY_CHOOSE_US.REASONS.THREE.TITLE}
          description={consts.WHY_CHOOSE_US.REASONS.THREE.DESCRIPTION}
        />
      </div>
    </div>
  )
}
