import { PropsWithChildren, createContext, useCallback } from "react"
import languages, { langName, langObjs } from "../../consts/language"
import usePersistedState from "../../hooks/usePersistedState"

const langKeys = Object.keys(languages) as langName[]

export const LangContext = createContext({
  consts: languages[langKeys[0]] as langObjs,
  name: langKeys[0] as string,
  handleLanguageChange: (langType: langName) => {},
})

const LanguageProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [language, setLanguage] = usePersistedState("lang", langKeys[0])

  const handleLanguageChange = useCallback(
    (langType: langName) => {
      setLanguage(langType)
    },
    [setLanguage]
  )

  return (
    <LangContext.Provider
      value={{
        consts: languages[language],
        handleLanguageChange,
        name: language,
      }}
    >
      {children}
    </LangContext.Provider>
  )
}

export default LanguageProvider
