export const TextBox = ({
  icon,
  text,
  description,
}: {
  icon: any
  text: string
  description: string
}) => {
  return (
    <div
      style={{
        margin: "48px 0px",
        maxWidth: "500px",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "24px",
      }}
    >
      <div style={{ width: "48px", height: "48px" }}>
        <img style={{ display: "block", width: "48px" }} src={icon} alt="" />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          gap: "0.5em",
        }}
      >
        <h3>{text}</h3>
        <span style={{ textAlign: "justify" }}>{description}</span>
      </div>
    </div>
  )
}
