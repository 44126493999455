import { useParams } from "react-router-dom"
import { Footer } from "../../Components/Footer/Footer"
import { Navbar } from "../../Components/Navbar/Navbar"
import { events } from "../../helpers/events"

import { Redirect } from "../Redirect/Redirect"
import "./style.css"
import React from "react"

export const EventsFull = () => {
  const { postId } = useParams()

  const filteredEvent = events.filter((event) => event.id === postId)[0]

  const relativeTime = new Intl.RelativeTimeFormat("pt-BR", { numeric: "auto" })
  const currentDate = new Date()
  const eventDate = filteredEvent?.date
  const timeDifference = eventDate?.getTime() - currentDate.getTime()
  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24))

  const isEventFinished = eventDate < currentDate

  return filteredEvent ? (
    <section>
      <Navbar />
      <div style={{ marginTop: "64px", width: "100%" }}>
        <img
          src={filteredEvent.banner}
          alt=""
          style={{ width: "100%", objectFit: "cover" }}
        />
        <div style={{ marginTop: "48px", padding: "0px 40px" }}>
          <div className="events-full-info-container">
            <h1 style={{ maxWidth: "800px" }}>{filteredEvent.title}</h1>
            <div className="state-container">
              <div
                className="state"
                style={{
                  backgroundColor: `${isEventFinished ? "#9a0000" : "#449a00"}`,
                }}
              >
                {isEventFinished ? "Evento finalizado" : "Em breve"}
              </div>
              <div className="date">
                {relativeTime.format(daysDifference, "day")}
              </div>
            </div>
          </div>
          {filteredEvent?.body?.map((text) => (
            <div className="events-full-text-container">
              <h3>{text.title}</h3>
              {text.body.split("\n").map((line, index) => (
                <p
                  key={index}
                  style={{ marginTop: "16px", textAlign: "justify" }}
                >
                  {line}
                  <br />
                </p>
              ))}
            </div>
          ))}
          <p style={{ marginTop: "16px", fontWeight: "bold" }}>
            Atenciosamente,
            <br />{" "}
            <span style={{ marginTop: "8px", display: "block" }}>
              Equipe IronHouse Gym.
            </span>
          </p>
        </div>
      </div>

      {!!filteredEvent.images.length && (
        <section>
          <div className="event-section-paper" style={{ marginTop: "64px" }}>
            <div className="event-section-images-container">
              {filteredEvent.images.map((image) => (
                <div className="gallery-section-img-item-container">
                  <img
                    loading="lazy"
                    src={image}
                    alt=""
                    className="gallery-image gallery-image-2"
                  />
                </div>
              ))}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "24px",
            }}
          ></div>
        </section>
      )}

      <Footer />
    </section>
  ) : (
    <Redirect />
  )
}
